import Modal from '@//components/modal';
import {
    createLyricTaskApi,
    createMusicTaskApi,
    createPhotoTaskApi,
    getRandomStyle,
    queryPhotoTaskApi,
    queryTaskApi,
    searchTopicApi
} from '@/apis/createMusic';
import {getGistAPI} from '@/apis/product';
import ButtonGradientPr from '@/components/buttonGradientPr';
import Header from '@/components/header';
import {toast} from '@/components/modals';
import {useAudioPlayerStore, useCreateMusicStore} from '@/store';
import user from '@/utils/user';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {Empty, Input, Popover, Spin, Switch} from 'antd';
import loadsh from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Outlet,Link} from 'react-router-dom';
import {AudioPanel} from '../../components/audioPanel';
import {UploadImageCrop} from '../../components/uploadImage';
import FeedBack from './components/feedback';
import ImgLoading from './components/imgloading';
import './index.scss';

import  {Captcha} from '@/components/aliyun';
import dataCapture from '@/utils/dataCapture';

const {TextArea} = Input;
let lyricTaskTimer;
let photoCreateTimer;
const Index = () => {
    const {playing, isPanelShow} = useAudioPlayerStore();
    const contentEditableRef = useRef(null);
    const scrollRef = useRef(null);
    const [gist, setGist] = useState('请输入'); //默认提示词
    const [prompt, setPrompt] = useState(''); // 输入的提示词
    const [tagData, setTagData] = useState([]); // 提示词集合
    const [pageType, setPageType] = useState(1); // 页面状态
    const [customLyricFlag, setCustomLyricFlag] = useState(false); // 是否自定义歌词
    const [lyric, setLyric] = useState(''); //歌词
    const [songTitle, setSongTitle] = useState(''); //歌名
    const [topicFlag, setTopicFlag] = useState(false); // 是否话题
    const [topic, setTopic] = useState(''); //话题
    const [showTopicList, setShowTopicList] = useState(false); //是否展示话题弹层
    const [topicList, setTopicList] = useState([]); //话题列表
    const [imageUrl, setImageUrl] = useState(''); //上传的图片
    const [errMsg, setErrmsg] = useState('');
    const [promptLoading, setPromptLoading] = useState(false);
    const [lyricLoading, setLyricLoading] = useState(false);
    const [songTitleLoading, setSongTitleLoading] = useState(false);
    const [getTopicListLoading, setGetTopicListLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const {data, setAgainData} = useCreateMusicStore();
    const [style, setStyle] = useState(''); // 风格
    const [randomModalVis, setRandomModalVis] = useState(false);
    const [aiCreateModalVis, setAiCreateModalVis] = useState(false);

    const [queueList, setQueueList] = useState(false); // 创作队列的弹窗
    const [submitMusic, setSubmitMusic] = useState(false); //提交成功的弹窗
    const [pureMusic, setPureMusic] = useState(false); // 是纯音乐还是歌曲
    // 获取随机描述语
    const getGistData = async (isSetPrompt = false) => {
        try {
            const {retcode, errmsg, message = {}} = await getGistAPI();
            dataCapture.sendData('click', undefined, 'random_prompt', '');
            if (retcode === 100) {
                if (isSetPrompt) {
                    setPrompt(message?.words || '请输入');
                } else {
                    setGist(message?.words || '请输入');
                }
            } else {
                toast.show(errmsg || '获取失败');
            }
        } catch (error) {}
    };
    // 随机提示词
    const randomPrompt = () => {
        const getRandom = sessionStorage.getItem('randomTip');

        if (prompt && !getRandom) {
            setRandomModalVis(true);
        } else {
            getGistData(true);
        }
    };
    // 获取随机风格
    const getStyle = async () => {
        try {
            const {retcode, errmsg, message} = await getRandomStyle();
            dataCapture.sendData('click', undefined, 'random_style', '');
            if (retcode === 100) {
                setTagData(message);
            } else {
                toast.show(errmsg || '获取失败');
            }
        } catch (error) {}
    };
    // 换一批
    const changeBatch = () => {
        scrollRef.current.scrollLeft = 0;
        getStyle();
    };
    //  首次进入页面，获取随机提示词和风格
    useEffect(() => {
        clearTimeout(lyricTaskTimer);
        clearTimeout(photoCreateTimer);
        getGistData();
        getStyle();
    }, []);
    // 做同款
    useEffect(() => {
        console.log(data, '同款数据');
        setPageType(1);
        setImageUrl('');
        const {prompt, lyric, songTitle, topic, style} = data;
        setStyle(style || '');

        setPrompt(prompt);
        setSongTitle(songTitle);

        if (lyric) {
            setCustomLyricFlag(true);
            setLyric(lyric);
        } else {
            setCustomLyricFlag(false);
            setLyric('');
        }
        if (topic) {
            setTopicFlag(true);
            setTopic(topic);
        } else {
            setTopicFlag(false);
            setTopic('');
        }
    }, [data, setAgainData]);
    //字符串插入
    function insertString(str, insertStr, index) {
        let left = str.slice(0, index);
        let center = insertStr;
        let right = str.slice(index);
        return `${left}${
            !left ? '' : left[left.length - 1] === ',' ? '' : ','
        }${center}${!right ? '' : right[0] === ',' ? '' : ','}${right}`;
        // return str.slice(0, index + 1) + insertStr + str.slice(index + 1);
    }
    //创意标签选中
    const tagSelected = (item) => {
        contentEditableRef?.current.focus();
        const index =
            contentEditableRef?.current.resizableTextArea.textArea
                .selectionStart;
        const result = insertString(prompt, `${item.style_name}`, index);
        // console.log(result);
        setPrompt(result.substring(0, 120));
    };
    //查询歌词任务
    const queryAiCreateTask = async (id) => {
        try {
            setLyricLoading(true);
            setSongTitleLoading(true);
            const {
                retcode,
                errmsg,
                message = {}
            } = await queryTaskApi({task_id: id});
            if (retcode === 100) {
                if (message?.is_finished) {
                    clearTimeout(lyricTaskTimer);
                    setLyricLoading(false);
                    setSongTitleLoading(false);
                } else {
                    lyricTaskTimer = setTimeout(() => {
                        queryAiCreateTask(id);
                    }, 2000);
                }
                const str = message.content || '';
                const str1 = str.split('歌词：')[1].split('歌名：')[0];
                const str2 = str.split('歌名：')[1].split('风格：')[0];
                const str3 = str.split('风格：')[1];
                // 成功  塞数据                
                setStyle(str3);
                setSongTitle(str2.replace(/\r?\n|\r/g, ""));
                setLyric(str1);
            } else {
                toast.show(errmsg);
                setLyricLoading(false);
                setSongTitleLoading(false);
            }
        } catch (error) {}
    };
    // 创建歌词任务
    const aiCteateTask = async () => {
        try {
            const {retcode, errmsg, message} = await createLyricTaskApi({
                prompt: prompt || gist
            });
            if (retcode === 100) {
                queryAiCreateTask(message?.task_id);
            } else {
                toast.show(errmsg);
            }
        } catch (error) {}
    };
    // 已有歌词弹窗提示，没有直接创建
    const aiCreate = () => {
        const getAiLyrics = sessionStorage.getItem('aiLyrics');

        user.forceLogin().then((ok) => {
            if (ok) {
                if (lyric && !getAiLyrics) {
                    setAiCreateModalVis(true);
                } else {
                    aiCteateTask();
                }
            } else {
                toast.show('请先登录');
            }
        });
    };

    // 获取话题
    let debouncedGetTopicList = useCallback(
        loadsh.debounce(async (val) => {
            console.log(val);
            setGetTopicListLoading(true);
            setShowTopicList(true);
            let {
                retcode,
                errmsg,
                message = {}
            } = await searchTopicApi({
                keywords: val
            });
            if (retcode === 100) {
                setTopicList(
                    message?.subject_list && message?.subject_list.length>0 ? message.subject_list : [{ "subject" : val }]
                );
            } else {
                toast.show(errmsg);
            }
            setGetTopicListLoading(false);
        }, 1000),
        []
    );

    const topicSelectDom = useMemo(() => {
        return (
            <Spin spinning={getTopicListLoading}>
                <div className="topic-box">
                    {topicList?.length > 0 ? (
                        topicList.map((item, index) => {
                            return (
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowTopicList(false);
                                        setTopic(item.subject);
                                    }}
                                    className={`topic-item`}
                                    key={item.id}>
                                    <span
                                        className={`${
                                            item.is_hot && 'linear-txt-color'
                                        }`}>
                                        {item.subject}
                                    </span>
                                </div>
                            );
                        })
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="暂无数据"></Empty>
                    )}
                </div>
            </Spin>
        );
    }, [topicList, getTopicListLoading]);

    /**
     * 页面类型切换
     * 1:文生音乐 ， 2：图片上传阶段 ， 3:图生音乐
     */
    const pageTypeSelect = (type) => {
        user.forceLogin().then((ok) => {
            if (ok) {
                setSubmitLoading(false);
                setPrompt('');
                setLyric('');
                setSongTitle('');
                setTopic('');
                setImageUrl('');
                setErrmsg('');
                setStyle('');
                setPromptLoading(false);
                setLyricLoading(false);
                setSongTitleLoading(false);
                if (type === 3) {
                    //
                    setShowTopicList(true);
                    setCustomLyricFlag(true);
                    setTopicFlag(true);
                } else {
                    setShowTopicList(false);
                    setCustomLyricFlag(false);
                    setTopicFlag(false);
                }
                console.log(type);
                setPageType(type);
            } else {
                toast.show('请先登录');
            }
        });
    };
    // 数据提交
    const onSubmit = async () => {
        setSubmitLoading(true);
        const params = {
            prompt: prompt || gist,
            lyric,
            subject: topic,
            title: songTitle,
            prompt_img: imageUrl,
            type: pureMusic?2:1,
            style
        };
        const {retcode, errmsg} = await createMusicTaskApi(params);
        if (retcode === 100) {
            // toast.show('歌曲创建成功，请到我的创作列表查看');
            setSubmitMusic(true);
            // 创建完成 ， 回到页面
            setErrmsg('');
            setImageUrl('');
            // setStyle('');
            if (pageType === 3) {
                setPageType(2);
            } else {
                setPageType(1);
            }
            // 通知刷新创建中列表
        } else {
            // toast.show(errmsg);
            setQueueList(true);
        }
        setSubmitLoading(false);

        console.log(params);
    };

    // 轮训接口 获取任务状态
    const refreshCreateApi = async (task_id) => {
        const {retcode, message, errmsg} = await queryPhotoTaskApi({task_id});
        if (retcode === 100) {
            if (message?.is_finished) {
                clearTimeout(photoCreateTimer);
                const str = message?.content;
                const str1 = str?.split('内容：')[1]?.split('歌词：')[0] || '';
                const str2 = str?.split('歌词：')[1]?.split('歌名：')[0] || '';
                const str3 = str?.split('歌名：')[1]?.split('风格：')[0] || '';
                const str4 = str?.split('风格：')[1] || '';
                console.log(str1);
                console.log(str2);
                console.log(str3);
                console.log(str4);
                // 成功  塞数据
                setPrompt(str1.replace(/\r?\n|\r/g, ""));
                setLyric(str2);
                setSongTitle(str3.replace(/\r?\n|\r/g, ""));
                setStyle(str4);

                setPromptLoading(false);
                setLyricLoading(false);
                setSongTitleLoading(false);
                setSubmitLoading(false);
            } else {
                photoCreateTimer = setTimeout(() => {
                    refreshCreateApi(task_id);
                }, 2000);
            }
        } else {
            toast.show(errmsg);
            setTimeout(() => {
                setErrmsg('');
                setImageUrl('');
                setPageType(2);
            }, 1500);
            setPromptLoading(false);
            setLyricLoading(false);
            setSongTitleLoading(false);
            setSubmitLoading(false);
        }
    };
    // 上传图片 创建任务
    const uploadImg = async () => {
        try {
            setSubmitLoading(true);
            const {retcode, errmsg, message} = await createPhotoTaskApi({
                url: imageUrl
            });
            console.log(retcode, errmsg, message);
            if (retcode === 100) {
                setPromptLoading(true);
                setLyricLoading(true);
                setSongTitleLoading(true);
                setPageType(3);
                setCustomLyricFlag(true);
                refreshCreateApi(message.task_id);
            } else {
                toast.show(errmsg);
                setSubmitLoading(false);
            }
        } catch (error) {
            setSubmitLoading(false);
        }
    };
    const scrollTo = (direction) => {
        let step = 50;
        if (direction === 'left') {
            scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - step;
        } else {
            scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + step;
        }
    };
    return (
        <div className="root">
            <div className="top">
                <div className="left" onClick={() => setShowTopicList(false)}>
                    <Link to="/music">
                        <img
                            className="logo"
                            src={require('../../assets/images/logo_text.png')}
                            alt=""
                        />
                    </Link>

                    {pageType === 3 && imageUrl && (
                        <>
                            <div className="prompt-box">
                                <span className="prompt-left">
                                    <i className="prompt-icon pic-icon"></i>
                                    <span className="prompt-text">
                                        图片创意
                                    </span>
                                </span>
                                <div className={`prompt-right ${submitLoading ? 'no-click' : ''}`} onClick={()=>{setPureMusic(!pureMusic)}}>
                                    <span className={!pureMusic?'active':''}>歌曲</span>
                                    <span className={pureMusic?'active':''}>纯音乐</span>
                                </div>
                            </div>
                            <div className="photo-create-box">
                                <img className="filter-img" src={imageUrl} alt="" />
                                <img
                                    className="photo-create-img"
                                    src={imageUrl}
                                    alt=""
                                />
                            </div>
                        </>
                    )}

                    {pageType === 1 || pageType === 3 ? (
                        <div>
                            {/* 创意 */}
                                <div className="prompt-box">
                                    <span className="prompt-left">
                                        <i className="prompt-icon"></i>
                                        <span className="prompt-text">
                                            音乐创意 
                                        </span>
                                    </span>

                                    {
                                        pageType === 1 &&(
                                            <div className={`prompt-right ${submitLoading ? 'no-click' : ''}`} onClick={()=>{setPureMusic(!pureMusic)}}>
                                                <span className={!pureMusic?'active':''}>歌曲</span>
                                                <span className={pureMusic?'active':''}>纯音乐</span>
                                            </div>
                                        )
                                    }
                                    
                                </div>
                                {
                                    promptLoading?
                                        <ImgLoading/>
                                    :(
                                    <div className="textarea-box">
                                        <TextArea
                                            ref={contentEditableRef}
                                            placeholder={gist}
                                            className="input"
                                            maxLength={120}
                                            value={prompt}
                                            onChange={(e) => {
                                                setPrompt(e.target.value);
                                            }}
                                            style={{ height: 154, resize: 'none' }}
                                        />
                                        {pageType === 1 && (
                                            <span
                                                className="prompt-random random-box"
                                                onClick={randomPrompt}>
                                                <i className="random-icon"></i>随机
                                            </span>
                                        )}
                                        <span className="count">
                                            {prompt?.length || 0}/120
                                        </span>
                                    </div>
                                    )
                                }
                            {pageType === 1 && (
                                <div className="tag-select-box">
                                    <LeftOutlined
                                        onClick={() => scrollTo('left')}
                                        style={{
                                            marginRight: '10px',
                                            color: '#898989'
                                        }}
                                    />
                                    <div
                                        className="tag-scroll-box"
                                        ref={scrollRef}>
                                        {tagData.map((item, index) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className="tag-item"
                                                    onClick={(e) => {
                                                        if (promptLoading)
                                                            return;
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        tagSelected(item);
                                                    }}>
                                                    <span className="tag-label">
                                                        {item.style_name}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <RightOutlined
                                        onClick={() => scrollTo('right')}
                                        style={{
                                            marginLeft: '10px',
                                            color: '#898989'
                                        }}
                                    />
                                    <div className="change-batch">
                                        <span
                                            className="prompt-random"
                                            style={{width: '74px',height: '32px', borderRadius: '15px'}}
                                            onClick={changeBatch}>
                                            <i className="random-style-icon"></i>
                                            换一批
                                        </span>
                                    </div>
                                </div>
                            )}
                            {/* 歌词 */}
                            {
                                !pureMusic &&(<>
                                        <div className="prompt-box m-t-20">
                                        <span className="prompt-left">
                                            <i className="prompt-icon custom-icon"></i>
                                            <span className="prompt-text">
                                                自定义歌词
                                            </span>
                                        </span>
                                        {pageType === 1 && (
                                            <Switch
                                                className={
                                                    customLyricFlag
                                                        ? 'switch-selected'
                                                        : 'switch-default'
                                                }
                                                checked={customLyricFlag}
                                                onChange={(val) => {
                                                    setLyric('');
                                                    setSongTitle('');
                                                    setCustomLyricFlag(val);
                                                }}
                                            />
                                        )}
                                    </div>
                                </>)
                            }
                            
                            {(customLyricFlag && !pureMusic) && (
                                <>
                                    {
                                        lyricLoading?<ImgLoading/>:(
                                        <div className="textarea-box">
                                        <TextArea
                                            placeholder={'请输入歌词'}
                                            className="input"
                                            maxLength={3000}
                                            value={lyric}
                                            onChange={(e) => {
                                                setLyric(e.target.value);
                                            }}
                                            style={{ height: 230, resize: 'none' ,paddingBottom: '31px'}}
                                        />
                                        <div className='custom-lyric'>
                                            {pageType === 1 && (
                                                <span
                                                    className="prompt-random ai-create-box"
                                                    onClick={aiCreate}>
                                                    <i className="ai-create"></i>
                                                    AI生成
                                                </span>
                                            )}

                                            <span className="count">
                                                {lyric?.length || 0}/3000
                                            </span>
                                        </div>
                                        </div>
                                        )
                                    }
                                </>
                            )}
                            {/* 歌名 */}
                            {(customLyricFlag || pureMusic) && (
                                <>
                                    <div className="prompt-box m-t-20">
                                        <span className="prompt-left">
                                            <i className="prompt-icon soneTitle-icon"></i>
                                            <span className="prompt-text">
                                                歌名
                                            </span>
                                        </span>
                                    </div>
                                        {
                                            songTitleLoading?<ImgLoading/>:(
                                            <div className="textarea-box">
                                            <Input
                                                placeholder={'请输入歌名'}
                                                className="input"
                                                style={{
                                                    minHeight: '48px',
                                                    padding: '10px 18px'
                                                }}
                                                maxLength={16}
                                                value={songTitle}
                                                onChange={(e) => {
                                                    setSongTitle(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <span className="num">
                                                {songTitle?.length || 0}/16
                                            </span>
                                            </div> 
                                            )
                                        }
                                        
                                </>
                            )}

                            {/* 话题 */}
                            <div className="prompt-box m-t-20">
                                <span className="prompt-left">
                                    <i className="prompt-icon topic-icon"></i>
                                    <span className="prompt-text">参与话题</span>
                                </span>
                                <Switch
                                    className={
                                        topicFlag
                                            ? 'switch-selected'
                                            : 'switch-default'
                                    }
                                    checked={topicFlag}
                                    onChange={(val) => {
                                        setTopic('');
                                        setTopicFlag(val);
                                    }}
                                />
                            </div>
                            {topicFlag && (
                                <Popover
                                    zIndex={1000}
                                    trigger={['click']}
                                    getPopupContainer={() =>
                                        document.getElementsByClassName(
                                            'root'
                                        )[0]
                                    }
                                    destroyTooltipOnHide
                                    fresh={false}
                                    autoAdjustOverflow={false}
                                    open={showTopicList}
                                    placement="top"
                                    content={topicSelectDom}
                                    arrow={false}>
                                    <div className="textarea-box">
                                        <Input
                                            placeholder={'请输入话题'}
                                            className="input"
                                            style={{
                                                minHeight: '48px',
                                                padding: '10px 18px',
                                                paddingLeft: '38px'
                                            }}
                                            maxLength={16}
                                            value={topic}
                                            onChange={(e) => {
                                                setTopic(e.target.value);
                                                user.forceLogin().then((ok) => {
                                                    if (ok) {
                                                        debouncedGetTopicList(
                                                            e.target.value
                                                        );
                                                    } else {
                                                        toast.show('请先登录');
                                                    }
                                                });
                                            }}
                                        />
                                        <span className="topic-input-icon">
                                            <img
                                                alt=""
                                                src={require('../../assets/images/newPage/topic-input-icon.png')}
                                            />
                                        </span>
                                        <span className="num">
                                            {topic?.length || 0}/16
                                        </span>
                                    </div>
                                </Popover>
                            )}
                        </div>
                    ) : (
                        <>
                        <div className="prompt-box">
                            <span className="prompt-left">
                                <i className="prompt-icon pic-icon"></i>
                                <span className="prompt-text">
                                    图片创意
                                </span>
                            </span>
                            <div className={`prompt-right ${submitLoading ? 'no-click' : ''}`} onClick={()=>{setPureMusic(!pureMusic)}}>
                                <span className={!pureMusic?'active':''}>歌曲</span>
                                <span className={pureMusic?'active':''}>纯音乐</span>
                            </div>
                        </div>
                        <div className="upload-box">
                            
                            <UploadImageCrop
                                className="input-file"
                                accept="image/*"
                                saveSize={{width: 400, height: 400}}
                                selectSize={{width: 400, height: 400}}
                                setUrl={(val) => {
                                    console.log(val, '11111');
                                    setImageUrl(val);
                                }}
                                setPercent={(val) => {
                                    // console.log(val);
                                }}
                                fileSize={20}
                                errMsg={(errMsg) => {
                                    setErrmsg(errMsg);
                                }}
                            />
                            {!!imageUrl ? (
                                <div className="select-img-box" key={imageUrl}>
                                    <img
                                        className="select-img"
                                        src={imageUrl}
                                        alt=""
                                    />
                                    <span className="again">重新上传</span>
                                </div>
                            ) : (
                                <div className="upload-btn-box" key={imageUrl}>
                                    <img
                                        style={{
                                            cursor: 'pointer',
                                            display: 'inline-block'
                                        }}
                                        src={require('../../assets/images/newPage/upload-btn.png')}
                                        alt=""
                                    />
                                    <span className="upload-tips">
                                        图片大小 ≤ 20MB
                                    </span>
                                </div>
                            )}
                            {!!errMsg && (
                                <span className="errMsg-box">{errMsg}</span>
                            )}
                        </div>
                        </>
                    )}

                    {/* 提交按钮 */}
                        <ButtonGradientPr
                            // className="create-btn"
                            className={`create-btn ${submitLoading ? 'create-btn-loading' : ''}`}  
                            style={{
                                fontSize: 18,
                                height: 48,
                                marginTop: 12
                            }}
                            onClick={() =>
                                user.forceLogin().then((ok) => {
                                    if (ok) {
                                        console.log(pageType, !!imageUrl);
                                        if (pageType === 2) {
                                            if (!imageUrl) {
                                                toast.show('请上传图片');
                                                return;
                                            }
                                            uploadImg();
                                        } else {
                                            onSubmit();
                                        }
                                    } else {
                                        toast.show('请先登录');
                                    }
                                })
                            }>
                            <div className="create-btn-bg-box">
                                <span>
                                    {pageType === 2 ? '生成创意' : '生成音乐'}
                                </span>
                            </div>
                        </ButtonGradientPr>
                    <div className="tips">内容由AI大模型生成，请仔细甄别</div>
                </div>
                <div className="right">
                    <Header logo={false} />
                    <Outlet />
                </div>
            </div>
            {isPanelShow && <div className="bottom"></div>}
            <AudioPanel />
            <Captcha />

            <Modal
                content={'重新随机将覆盖当前音乐创意，确定要随机么？'}
                open={randomModalVis}
                title="注意"
                onOk={() => {
                    getGistData(true);
                    setRandomModalVis(false);
                    sessionStorage.setItem('randomTip','ok');
                }}
                onCancel={() => setRandomModalVis(false)}
            />
            <Modal
                content={'AI生成将覆盖当前歌词，确定要生成么？'}
                open={aiCreateModalVis}
                title="注意"
                onOk={() => {
                    aiCteateTask();
                    setAiCreateModalVis(false);
                    sessionStorage.setItem('aiLyrics','ok');
                }}
                onCancel={() => setAiCreateModalVis(false)}
            />
            {/* 创作队列已满 */}
            <Modal
                width={420}
                className='queue-full-modal'
                content={(<div className='queue-full-modal-content'><div className='queue-bg'></div><div className='font-content'><h1 className='queue-full'>当前创作队列已满！</h1><span className='queue-full-font'>请耐心等待歌曲生成后再进行新的创作！</span></div></div>)}
                title=""
                open={queueList}
                onOk={() => {
                    setQueueList(false)
                }}
                onCancel={() => setQueueList(false)}
                footer={[
                    <div className='queue-full-modal-footer' key="ok" onClick={() => {
                        setQueueList(false)
                    }}>
                      ok
                    </div>
                ]}
            />
            {/* 提交成功 */}
            <Modal
                width={420}
                className='queue-full-modal submit-full-modal'
                content={(<div className='queue-full-modal-content'><div className='queue-bg queue-bg-submit'></div><div className='font-content'><h1 className='queue-full'>提交成功！</h1><span className='queue-full-font'>请耐心等待歌曲生成！</span></div></div>)}
                title=""
                open={submitMusic}
                onOk={() => {
                    setSubmitMusic(false)
                }}
                onCancel={() => setSubmitMusic(false)}
                footer={[
                    <div className='queue-full-modal-footer' key="ok" onClick={() => {
                        setSubmitMusic(false)
                    }}>
                      ok
                    </div>
                ]}
            />
            <FeedBack />
        </div>
    );
};
export default Index;
