import React from 'react';
import {Input} from 'antd';
import './loading.scss';

const ImgLoading = () => {
  return (
    <div className='loading-content'>
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <Input variant="filled" disabled className='loading-input'/>
    </div>
  );
}

export default ImgLoading;
