import b64ToUint8Array from '@/utils/b64ToUnit8';
import {useCallback} from 'react';
import 'react-image-crop/dist/ReactCrop.css';

import uploadFileAPI from '@/apis/upload';
import {toast} from '../modals';
import './index.scss';

function getFileSizeInMB(file) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB;
}

function UploadImageCrop({
    setUrl,
    setPercent,
    saveSize,
    selectSize,
    className,
    accept = 'image/*',
    errMsg,
    fileSize
}) {
    const onSelectFileChange = useCallback((e) => {
        errMsg && errMsg('');
        if (e.target.files && e.target.files.length > 0) {
            if (fileSize) {
                let size = getFileSizeInMB(e.target.files[0]);
                console.log(size);
                if (Number(size.toFixed(2)) > fileSize) {
                    errMsg &&
                        errMsg(
                            `图片过大，无法上传，请选择≤${fileSize}MB的图片`
                        );
                    return false;
                }
            }

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const src = reader.result?.toString() || '';
                // console.log(src, 'src');
                onSubmit(src);
            });

            reader.readAsDataURL(e.target.files[0]);
            e.target.value = '';
        }
    }, []);

    function onSubmit(data) {
        // console.log(data);
        const b64Image = data;
        // 实际应该是上传完成返回的url
        // setUrl(b64Image);
        var u8Image = b64ToUint8Array(b64Image);

        try {
            uploadFileAPI(new Blob([u8Image], {type: 'image/jpg'}), (rs) => {
                if (rs.status / 1 === 1) {
                    const {loaded, total} = rs.ProgressEvent;
                    const progress = Math.round((loaded / total) * 100);
                    setPercent(progress);
                } else if (rs.status / 1 === 2) {
                    setUrl(rs.data.message.imageurl);
                } else {
                    toast.show(rs.error);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <input
                className={className}
                accept={accept}
                title="选择图片"
                type="file"
                name="file"
                onChange={onSelectFileChange}
                style={{cursor: 'pointer'}}
            />
        </div>
    );
}

export {UploadImageCrop};
