import {toast} from '@/components/modals';
import axios from 'axios';
import user from './user';
import {captcha} from '../components/aliyun';

/**
 * 根域名
 * 超时时间
 * 请求拦截器 / 响应拦截器
 *
 * mock地址：http://192.168.200.92:5050
 */
let baseURL =
    process.env.NODE_ENV === 'development'
        ? 'http://192.168.200.139:8001'
        : 'https://appapi.caichong.net';
baseURL = 'https://appapi.caichong.net';
//   console.log(baseURL,"baseURL");
const request = axios.create({
    baseURL: baseURL,
    timeout: 50000000,
    headers: {
        'X-Platform': 'pc'
    }
});

// 请求拦截器，
request.interceptors.request.use(
    (config) => {
        // console.log(config);
        const token = user.token || localStorage.getItem('_TOKEN');
        const uuid = localStorage.getItem('UUID');
        if (token) {
            // config.headers.Authorization = `token ${token}`;
            config.headers['token'] = token;
        }
        if (uuid) {
            config.headers['uuid'] = uuid;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截
request.interceptors.response.use(
    (response) => {
        // 2xx 范围内的状态码都会触发该函数
        if(response.data.retcode === 203){
            localStorage.clear();
            toast.show('请重新登录');
            user.login();
        }
        return response.data;
    },
    (error) => {
        // 超出2xx 范围的状态码都会触发该函数

        try {
            console.log(error);
            // 监控401 token失效
            if(error.response.status === 500) {
                localStorage.clear();
                toast.show(error.message);
            }
            if(error.response.status === 208) {
                captcha.show(); // 触发验证码
            }
            if (error.response.status === 503) {
                let msg = error.response.data;
                toast.show(msg);
                // removeToken();
                // router.navigate('/login');
            }
            return Promise.reject(error);
        } catch (e) {
            console.log('request error:', e);
        }
    }
);

export {request};
