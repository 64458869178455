import { useEffect, useState } from "react";
import useModalFade from "@/hooks/useModalFade";
import Portal from "../portal";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import { toast } from "../toast";

import "./index.scss";
import { getMyInviteCodeAPI } from "@/apis/product";

const CodeItem = ({ item }) => {
    const copyText = (str) => {
        copy(`🌟 特别礼遇，只为你！小明送你的激活码：${str}，已准备就绪。立即访问https://www.chaoxin.com，AI音乐创作平台，激活你的音乐梦想，开启创作新篇章！🎶`);
        toast.show("已复制到剪贴板");
    }
    return (
        item.nickname != '' ?
            <li className="ac-code-used">
                <span className="ac-code">{item.code}</span>
                <span className="ac-code-user">{`用户“${item.nickname}“已经使用`}</span>
            </li>
            : <li>
                <span className="ac-code">{item.code}</span>
                <span className="ac-copy" onClick={() => copyText(item.code)}>复制</span>
            </li>
    )
};

const CodeContainer = ({ visible }) => {

    const [codes, setCodes] = useState([]);
    useEffect(() => {
        getMyInviteCodeAPI().then(rs => {
            try {
                if (rs.retcode == 100) {
                    setCodes(rs.message);
                }
            } catch (e) {
                console.log('invite.erro:', e);
                console.log('response:',rs);
            }
        })
    }, []);

    return (
        <Portal>
            <div className={classNames("active-alert-panel-bg", visible && "active-alert-panel-bg-v")}>
                <div className="alert-panel">
                    <div className="ap-header">
                        <span className="aph-bg"></span>
                        <span className="ap-close" onClick={() => alertActive.hide()}></span>
                    </div>
                    <div className="ap-content">
                        <h6>我的激活码（{codes.filter(item => item.status == 1).length}/{codes.length}）</h6>
                        <ul>
                            {
                                codes.map((item, i) => <CodeItem key={`ac-${i}`} item={item} />)
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </Portal>
    )
}

let alertActive = {};
const AlertActive = () => {

    const { show, visible, modalRef } = useModalFade();

    useEffect(() => {
        alertActive = {
            show: modalRef.current.show,
            hide: modalRef.current.hide
        }
        return ()=>{
            modalRef.current?.hide();
        }
    }, []);
    return (
        show && <CodeContainer visible={visible} />
    )
}

export {
    AlertActive,
    alertActive
}