import {create} from 'zustand';

const useAudioPlayerStore = create((set) => ({
    isPanelShow: false,
    setIsPanelShow: (status) => set(() => ({isPanelShow: status})),
    mid: 0,
    setMid: (mid) => set(() => ({mid: mid})),
    removeMid: () => set({mid: 0}),
    playing: false,
    setPlaying: (status) => set(() => ({playing: status}))
}));

export default useAudioPlayerStore;
