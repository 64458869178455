import { request,loginRequest } from '@/utils';

// 用户相关所有请求

/*
    获取手机验证码
    mobile	string
*/
function getAuthMobileCodeAPI(params) {
    return request({
        url: '/auth/getMobileAuthCode',
        method: 'POST',
        params: { ...params, invitetype: 2 } 
    });
}
/*
    提交手机验证码
    code	string
    mobile	string
 */
function submitMobileCodeAPI(params) {
    return request({
        url: '/auth/mobileVerify',
        method: 'POST',
        params: params
    });
}

/*
    提交用户个人信息，头像，昵称...
    nickname	string(必传)	昵称
    headimg	    string	头像文件地址
    token	    string
*/
function submitUserInfoAPI(params) {
    return request({
        url: '/user/updateUserInfo/',
        method: 'POST',
        params: params
    });
}

/*
    获取单个用户信息
    uid int
*/
function getUserInfoAPI(params) {
    return request({
        url: '/user/userInfo',
        method: 'GET',
        params: params
    });
}

/*
    获取当前登录用户的信息
    token string
*/
function getMyInfoAPI(params) {
    return request({
        url: '/user/myInfo',
        method: 'GET',
        data: params
    });
}

// 获取用户信息
function getGuestIdAPI() {
    return request({
        url: '/auth/index',
        method: 'GET'
    });
}

// 用户行为检测+验证 
function getRiskVerifyAPI(data) {
    return request({
        url: '/api/risk/verify',
        method: 'POST',
        data
    });
}

/**
 * 2024-09-24 新增接口  
 */
// 用户注册
function userRegister(data) {
    return request({
        url: '/user/register',
        method: 'POST',
        data
    });
}
// 用户登录
function userVerifyPassword(data) {
    return request({
        url: '/user/verifyPassword',
        method: 'POST',
        data
    });
}

export {
    getAuthMobileCodeAPI,
    submitMobileCodeAPI,
    submitUserInfoAPI,
    getUserInfoAPI,
    getMyInfoAPI,
    getGuestIdAPI,
    getRiskVerifyAPI,
    
    userRegister,
    userVerifyPassword
};
