import { request } from '@/utils';

/**
 * 
    生成歌曲提交
    promot	string	描述词
    style	int	    音乐类型
    instrumentsid	int
 */
function createMusicAPI(formData) {
    return request({
        url: '/music/createmusic',
        method: 'POST',
        params: formData
    })
}
/*
    提示词模版拉取
    page	string
    limit	int
*/
function getTemplateAPI(params) {
    return request({
        url: '/music/templatePrompt',
        method: 'GET',
        params: params
    });
}

/*
    根据推荐模版拉取提示词（默认，+摘取随机）
    style   string    模版风格标识
*/
function getGistAPI(params) {
    return request({
        url: '/music/templateGist  ',
        method: 'GET',
        params: params
    });
}

/*
    正在生成的列表
    page	int	页码
    limit	int	每页条数
*/
function getCreatingAPI(params) {
    return request({
        url: '/me/creating',
        method: 'GET',
        params: params
    });
}

/*
    拉取单个正在生成的作品状态
    mid	string	作品id
*/
function getCreatingSingleAPI(params) {
    return request({
        url: '/me/createStatus',
        method: 'GET',
        params: params
    });
}

/*
    我的作品列表
    page	int	页码
    limit	int	每页条数
*/
function getMyListAPI(params) {
    return request({
        url: '/music/my',
        method: 'GET',
        params: params
    });
}

/*
    系统推荐作品列表
    page	int	页码
    limit	int	每页条数 
*/
function getRecommendListAPI(params) {
    return request({
        url: '/music/recommendList',
        method: 'GET',
        params: params
    });
}

/*
    邀请码
    auth/makeInviteCode 生成
    auth/myInviteCode  获取个人已经生成的邀请码
    message{
        {code:"1231231asd2", "nickname":"hchuang"}
        {code:"1231231asaasd2", "nickname":"hchuang1"}
    }　
*/
function getMyInviteCodeAPI(params) {
    return request({
        url: '/auth/myInviteCode',
        method: 'GET',
        params: params
    });
}

/*
    拉取歌曲信息
    url: url,
    uyrics: lyrice,
*/
function getMusicSourceAPI(params) {
    return request({
        url: '/music/play',
        method: 'GET',
        params: params
    });
}

/**
 * 获取分享信息 
 * mid: string
*/
function getShareInfoAPI(params) {
    return request({
        url: '/music/shareInfo',
        method: 'GET',
        params: params
    });
}

/**
 * 编辑title
 *  Mid     string  `form:"mid"`
 * Title   string   `form:"title"`
*/
function updateMusicTitleAPI(params) {
    return request({
        url: '/music/updatetitle',
        method: 'POST',
        params: params
    });
}

/**
 * 下载mp4
 * mid: string
 * http://192.168.200.47:8001/music/downloadMp4?mid=3ba8f706384b9dd8716135ea5419f9ad
 * {
        percent      int     `json:"percent"`
        url          string  `json:"url"`
        status       int     `json:"status"`
    }
*/
function getMp4API(params) {
    return request({
        url: '/music/downloadMp4',
        method: 'POST',
        params: params
    });
}

/**
 * 分享落地页
 * mid: string
*/
function getSharePageAPI(params) {
    return request({
        url: '/music/share',
        method: 'GET',
        params: params
    });
}

/**
 * 点攒
 * musicInterestReq {
        Mid       string    `form:"mid"`
        Type      int       `form:"style"` 1点过，2
    }
 */
function likeupAPI(params) {
    return request({
        url: '/music/interest',
        method: 'POST',
        params: params
    });
}

window.getTest = (params) => {
    return request({
        url: (params.url),
        method: params.method || 'GET',
        params: params
    })
}

export {
    createMusicAPI,
    getTemplateAPI,
    getGistAPI,
    getCreatingAPI,
    getCreatingSingleAPI,
    getMyListAPI,
    getRecommendListAPI,
    getMyInviteCodeAPI,
    getMusicSourceAPI,
    getShareInfoAPI,
    updateMusicTitleAPI,
    getSharePageAPI,
    likeupAPI,
    getMp4API
};
