import {toast} from '@/components/modals';
import user from '@/utils/user';
import axios from 'axios';

let baseURL =
    process.env.NODE_ENV === 'development'
        ? 'http://192.168.200.41:8001'
        : 'https://appapi.caichong.net';
baseURL = 'https://appapi.caichong.net';
const uploadFileAPI = async (data, callback) => {
    const token = user.token || localStorage.getItem('_TOKEN');
    try {
        const formData = new FormData();
        formData.append('fileForm', data);

        try {
            await axios
                .post(`${baseURL}/user/uploadImg`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        token: token,
                        'X-Platform': 'pc'
                    },
                    onUploadProgress: (ProgressEvent) => {
                        callback({
                            status: 1,
                            ProgressEvent
                        });
                        // const { loaded, total } = ProgressEvent;
                        // const progress = Math.round((loaded / total) * 100);
                        // console.log(`Upload Progress: ${progress}%`);
                    }
                })
                .then((rs) => {
                    console.log(rs);
                    callback({
                        status: 2,
                        data: rs.data
                    });
                });
        } catch (error) {
            console.log(error);
            toast.show(error.message);
        }

        // console.log('Upload complete');
        // console.log('Response:', reponse.data);
    } catch (error) {
        console.log(error);
        callback({
            status: 0,
            error: error
        });
    }
};

export default uploadFileAPI;
