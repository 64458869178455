import { useEffect, useState } from "react";

const useDrag = ({
    axis,
    bounds,
    defaultPosition = { x: 0, y: 0 },
    onDragStart=()=>{},
    onDragStop=()=>{},
    ref
}) => {

    const [draging, setDraging] = useState(false);
    const [position, setPosition] = useState({
        x: defaultPosition.x || 0,
        y: defaultPosition.y || 0
    });
    useEffect(() => {
        const dragElm = ref.current;
        // const _position = {
        //     x: defaultPosition.x || 0,
        //     y: defaultPosition.y || 0
        // }
        dragElm.style.transform = `translate(${position.x}px, ${position.y}px)`;
        let offsetx = 0;
        let offsety = 0;
        const mouseDown = (event) => {
            event.stopPropagation();
            setDraging(true);
            const initx = event.pageX;
            const inity = event.pageY;
            const _translate = dragElm.style.transform;
            const [, _x, _y] = _translate.match(/(\d+).+(\d+)/);
            const x = _x / 1;
            const y = _y / 1;

            if (bounds?.parent) {
                if (bounds.parent) {
                    if (bounds.parent.bound === 'width') {
                        bounds.right = dragElm.parentNode.offsetWidth;
                    } else {
                        bounds.top = dragElm.parentNode.offsetHeight;
                    }

                }
            }
            onDragStart(x, y);
            const mouseMove = (event) => {
                const movex = event.pageX;
                const movey = event.pageY;

                offsetx = movex - initx + x;
                offsety = movey - inity + y;
                if (axis === 'x') {
                    offsety = 0;
                    if (typeof bounds.left === 'number') {
                        offsetx = Math.max(bounds.left, offsetx);
                    }
                    if (typeof bounds.right === 'number') {
                        offsetx = Math.min(bounds.right, offsetx);
                    }
                } else if (axis === 'y') {
                    offsetx = 0;
                    if (typeof bounds.bottom === 'number') {
                        offsety = Math.min(bounds.bottom, offsety)
                    }
                    if (typeof bounds.top === 'number') {
                        offsety = Math.max(bounds.top, offsety);
                    }
                }

                dragElm.style.transform = `translate(${offsetx}px, ${offsety}px)`;
                setPosition({ x: offsetx, y: offsety });
            }
            const mouseUp = () => {
                onDragStop(offsetx, offsety);
                setDraging(false);
                // onDragStop({ x: offsetx, y: offsety });
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('mouseup', mouseUp);
            }
            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        }
        dragElm.addEventListener('mousedown', mouseDown);

    }, [requestAnimationFrame]);

    return [
        position,
        draging
    ]

};

export default useDrag;