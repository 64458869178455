import { login } from "../modals";
// import { payPanel } from '../modals';
import classNames from 'classnames';
import user from '@/utils/user';
import { menu} from "../menu";


import './index.scss';
import { usePublicStore } from '@/store';
// const Member = memo(() => {

//     // useEffect(() => {
//     //     setPoints(18);
//     //     setLevel(0);
//     // })
//     return (
//         <div className="member" onClick={()=>user.forceLogin().then(ok=>{
//             if(ok){
//                 payPanel.show();
//             }
//         })}>
//             {
//                 user.points > 0 && (
//                     <div className="points">
//                         <span className="point-total">{user.points}</span>
//                         <span className="point-icon-white"></span>
//                     </div>
//                 )
//             }
//             {user.points>0&&user.member<1&&(<div className="line">|</div>)}
//             {(!user.isLogin()||user.member<2)&&<div className="upgrade" >升级会员</div>}
//         </div>
//     )
// })

const User_panel = () => {
    
    const isMenuShow = usePublicStore(state=>state.isMenuShow);

    // const [loginv, setLoginv] = useState(false);
    return (<div className="user-panel">
        {/* <Member /> */}
        {!user.isLogin() && (<div className="login-btn" onClick={()=>{login.show()}}>登录</div>)}
        <div className="menu">
            {
                user.isLogin() &&
                <>
                {/* <div className="active-code" onClick={()=>alertActive.show()}></div> */}
                <div className="avatar">
                    {/* <img src={user.avatar} alt="" /> */}
                    <img src={require('@/assets/images/avatar.png')} alt="" />
                    {user.member > 0 && (<span className={`vip-${user.member}`}></span>)}
                </div>
                </>
            }
            <div className={classNames("menu-btn", isMenuShow&&"menu-btn-open")} onClick={(e)=>menu.show(e.target)}>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>)
}

export default User_panel;
