import {create} from 'zustand';

const createMusicStore = create((set) => ({
    data: {
        prompt: '', // 创意
        lyric: '', // 歌词
        songTitle: '', //歌名
        topic: '', //话题
        style: '' //风格
    },
    setAgainData: (data) => {
        let newData = JSON.parse(JSON.stringify(data));
        if (newData.lyric && newData.lyric.indexOf('start') !== -1) {
            let str = '';
            let arr = JSON.parse(newData.lyric);
            for (let i = 0; i < arr.length; i++) {
                str += arr[i].text + '\n';
            }
            newData.lyric = str;
            set(() => ({data: newData}));
        } else {
            set(() => ({data: data}));
        }
    }
}));

export default createMusicStore;
