import { memo } from "react";

const ButtonGradientPr = memo(({
    className,
    style,
    onClick=()=>{},
    children
}) =>{

    return (
        <div
        className={className} 
        style={{
            minWidth:80,
            minHeight:36,
            background: '#363636',
            borderRadius: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            userSelect:'none',
            cursor: 'pointer'
            , ...style
        }}
            onClick={onClick}
        >
            <div>{children}</div>
        </div>
    )
});

export default ButtonGradientPr;