import {createBrowserRouter, Navigate} from 'react-router-dom';
import Main from '@/pages/main';
import NewPage from '@/pages/newPage';
// import MobileTemp from "@/pages/mobileTemp";
import {Suspense, lazy} from 'react';
const Share = lazy(() => import('@/pages/share'));
const About = lazy(() => import('@/pages/about'));
const Policy = lazy(() => import('@/pages/policy'));
const Page404 = lazy(() => import('@/pages/page404'));

const Music = lazy(() => import('@/pages/music'));
const MusicDetails = lazy(() => import('@/pages/music/details'));
const Topic = lazy(() => import('@/pages/topic'));
const TopicDetails = lazy(() => import('@/pages/topic/details'));
const Recording = lazy(() => import('@/pages/recording'));

const Test = lazy(() => import('@/pages/topic/infiniteScroll'));

// if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
//     window.location.replace('http://m.caichong.net' + window.location.pathname + window.location.search)
// }

const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/music" />,
        title: 'chaoxin'
    },
    {
        path: '/',
        element: <NewPage />,
        title: 'chaoxin',
        children: [
            {
                index: true,
                path: 'music',
                element: (
                    <Suspense>
                        <Music />
                    </Suspense>
                ),
                title: 'chaoxin - 音乐页面'
            },
            {
                path: 'topic',
                element: (
                    <Suspense>
                        <Topic />
                    </Suspense>
                ),
                title: 'chaoxin - 话题页面'
            },
            {
                path: 'topic/:id',
                element: (
                    <Suspense>
                        <TopicDetails />
                    </Suspense>
                ),
                title: 'chaoxin - 话题详情'
            },
            {
                path: 'music/:id',
                element: (
                    <Suspense>
                        <MusicDetails />
                    </Suspense>
                ),
                title: 'chaoxin - 音乐详情'
            },
            {
                path: 'recording',
                element: (
                    <Suspense>
                        <Recording />
                    </Suspense>
                ),
                title: 'chaoxin - 录音'
            }
        ]
    },

    {
        path: '/t',
        element: <Test />,
        title: 'chaoxin'
    },
    // {
    //     path: '/newPage',
    //     element: <NewPage />,
    //     title: 'chaoxin',
    //     children: [
    //         {
    //             path: 'music',
    //             element: (
    //                 <Suspense>
    //                     <Music />
    //                 </Suspense>
    //             ),
    //             title: 'chaoxin - 音乐页面'
    //         },
    //         {
    //             path: 'topic',
    //             element: (
    //                 <Suspense>
    //                     <Topic />
    //                 </Suspense>
    //             ),
    //             title: 'chaoxin - 话题页面'
    //         },
    //         {
    //             path: 'topic/:id',
    //             element: (
    //                 <Suspense>
    //                     <TopicDetails />
    //                 </Suspense>
    //             ),
    //             title: 'chaoxin - 话题详情'
    //         },
    //         {
    //             path: 'music/:id',
    //             element: (
    //                 <Suspense>
    //                     <MusicDetails />
    //                 </Suspense>
    //             ),
    //             title: 'chaoxin - 音乐详情'
    //         }
    //     ]
    // },
    {
        path: '/share',
        element: (
            <Suspense>
                <Share />
            </Suspense>
        ),
        title: 'chaoxin'
    },
    {
        path: '/about',
        element: (
            <Suspense>
                <About />
            </Suspense>
        ),
        title: 'chaoxin - 关于我们'
    },
    {
        path: '/about/policy/cc1',
        element: (
            <Suspense>
                <Policy cc={'cc1'} />
            </Suspense>
        ),
        title: 'chaoxin - 用户协议'
    },
    {
        path: '/about/policy/cc2',
        element: (
            <Suspense>
                <Policy cc={'cc2'} />
            </Suspense>
        ),
        title: 'chaoxin - 隐私协议'
    },

    {
        path: '/*',
        element: (
            <Suspense>
                <Page404 />
            </Suspense>
        ),
        title: 'chaoxin - 页面未找到'
    }
]);

export default router;
