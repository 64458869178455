const setMeta = (_meta) => {
    const meta = _meta || {
        title: 'chaoxin',
        type: 'website',
        img: '/logo512.png',
        url: window.location.href
    };
    document
        .querySelector('meta[property="og:title"]')
        .setAttribute('content', meta.title);
    document
        .querySelector('meta[property="og:type"]')
        .setAttribute('content', meta.type);
    document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', meta.img);
    document
        .querySelector('meta[property="og:url"]')
        .setAttribute('content', meta.url);
};
export default setMeta;
