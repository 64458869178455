import { useImperativeHandle, useEffect, useState, useRef, memo, useCallback } from "react";
import user from "@/utils/user";
// import { confirm, payPanel } from "../modals";

import Portal from "../portal";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePublicStore } from "@/store";
import "./index.scss";

let menu = {};
const Menu = memo(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [visible, setVisible] = useState(false);
    // const [wxShow, setWxShow] = useState(false);
    const menuRef = useRef(null);
    const selfRef = useRef(null);

    const parentNode = useRef(null);

    const setIsMenuShow = usePublicStore((state) => state.setIsMenuShow);
    // useEffect(() => {
    //     setIsMenuShow(show);
    // }, [show]);

    const globalClick = function (event) {
        if (!selfRef.current.contains(event.target) && !parentNode.current.contains(event.target)) {
            menu.hide();
        }
    }

    let timer;

    useImperativeHandle(menuRef, () => {
        return {
            show(menu_btn) {
                if (parentNode.current) {
                    return this.hide();
                } else {
                    setIsMenuShow(true);
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        setVisible(true)
                    }, 0)
                    parentNode.current = menu_btn;
                    document.addEventListener('click', globalClick);
                    return setShow(true);
                }
            },
            hide() {
                setIsMenuShow(false);
                parentNode.current = null;
                clearTimeout(timer);
                timer = setTimeout(() => {
                    setShow(false)
                }, 300)
                document.removeEventListener('click', globalClick);
                return setVisible(false)
            }
        }
    });
    useEffect(() => {
        menu = menuRef.current;
        return ()=>{
            menu.hide();
        }
    }, [])

    const onLogout = () => {
        user.logout()
        // confirm.show('确定退出吗？').then(isSure => {
        //     if (isSure) {
        //         user.logout()
        //     }
        // });
    }

    const toCreate = useCallback(() => {
        if (location.pathname.substring(1) !== "") {
            navigate('/');
        }
    }, [location,navigate])

    return (
        show &&
        <Portal>
            <div ref={selfRef} className={classNames("menu-panel", visible && "menu-panel-v")}>
                <div className="menu-panel-container">
                    <ul className="m-top">
                        <li className="m-create" onClick={toCreate}><span>创作台</span></li>
                        <li style={{
                            color: "rgba(17,17,17,.3)"
                        }} onClick={() => {
                        }}>会员（敬请期待）</li>
                    </ul>
                    <ul className="m-bottom">
                        {user.isLogin() ? <li onClick={onLogout}>登出</li> : <li onClick={user.login}>登录/注册</li>}
                    </ul>
                </div>
            </div>
        </Portal>
    )
});


export {
    menu,
    Menu
}