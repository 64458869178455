/**
 * 数据采集
 * 数据上报功能
 */
class DataCapture {
    uid='';
    uuid='';
    platform='web';
    page='';
    data={};
    action='click';
    model='';
    from='';
    #lastFrom;

    constructor() {
        this.init();
    }

    /**
     * 
     * @param {'GET'|'POST'} url 
     * @param {string} method 
     * @param {object} body 
     * @param {object} headers 
     */
    async fetchData(url, method = 'GET', body = null, headers = {}) {
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
                'X-Platform': this.platform,
            },
        };
    
        // 如果是 POST 或其他非 GET 请求，并且有 body，序列化它
        if (body && method !== 'GET' && method !== 'HEAD') {
            options.body = JSON.stringify(body);
        }

        // 如果是 GET 请求，处理查询参数
        if (method === 'GET' && body) {
            const params = new URLSearchParams(body).toString();
            url += (url.includes('?') ? '&' : '?') + params;
        }

    
        try {
            const response = await fetch(url, options);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    

            // 对于不需要返回值的请求，我们可以直接返回
            if (url.includes('beh.caichong.net/s.html')) {
                console.log('数据上报成功');
                return;
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Fetch error:', error);
            // throw error; // 重新抛出错误以便调用者处理
        }
    }

    async init() {
        this.isLogin();
        this.getPreviousPage();
        this.getCurrentPage();

        this.interceptClick();
    }

    async getUUID() {
       const res = await this.fetchData('https://appapi.caichong.net/device/getUuid?fingerPrint=')
       if(res.retcode === 100) {
            this.uuid = res.message.uuid;
            localStorage.setItem('UUID',this.uuid);
       }
       return this.uuid;
    }

    // 判断是否登录，如果登录，获取uid和token，如果没有登录，获取uuid
    isLogin() {
        const uinfo = localStorage.getItem('UINFO');
        const uuid = localStorage.getItem('UUID');
        if(uinfo) {
            const [uid] = uinfo.split('|');
            this.uid = uid;
        }
        if(!uuid) {
            this.getUUID();
        }else{
            this.uuid = uuid;
        }
    }

    // 获取上一个页面
    getPreviousPage() {
        const previousPage = document.referrer;
        this.from = previousPage;
        return previousPage;
    }

    // 获取当前页面
    getCurrentPage() {
        const currentPage = this.#matchUrl(window.location.pathname);
        this.page = currentPage;
    }

    #matchUrl(path) {
        const homePattern = /^\/music$/;
        const musicInfoPattern = /^\/music\/.+$/;
        const subjectListPattern = /^\/topic\/?$/;
        const subjectInfoPattern = /^\/topic\/.+$/;
        const recordingPattern = /^\/recording\/?$/;

        const login = document.querySelector('.login-panel-wrapper'); // 使用类名选择器
        const sign = document.querySelector('.invitation-code'); // 使用 ID 选择器
    
        if(login && sign) {
            return 'sign';
        }
        if(login) {
            return 'login';
        }

        if (homePattern.test(path)) {
            return 'home';
        } else if (musicInfoPattern.test(path)) {
            return 'musicinfo';
        } else if (subjectListPattern.test(path)) {
            return 'subject_list';
        } else if (subjectInfoPattern.test(path)) {
            return 'subject_info';
        }  else if (recordingPattern.test(path)) {
            return 'recording';
        } else {
            return undefined;
        }
        
    }

    /**
     * @param {'click' | 'load' | 'loop' | 'sign' | 'login'} action - 事件类型
     */
    changeAction(action) {
        // click 点击事件 load 加载事件 loop 停留事件 sign 注册事件 login 登录事件
        this.action = action;
    }

    // 获取全部属性
    getAllProperties() {
        return Object.fromEntries(Object.entries(this));
    }

    // 
    /**
     * 发送埋点数据请求
     * @param {'click' | 'load' | 'loop' | 'sign' | 'login'} action - 事件类型
     * @param {*} page - 页面
     * @param {'random_prompt' | 'random_style' | 'switch_music_list' | 'switch_subject_list' | 'play' | 'do_public_music' | 'same_music' | 'share' | 'do_tip_music' | 'do_interest' | 'subject_list' | 'subject_info' | 'login' | 'sign' | 'same_subject'} model - 功能
     * @param {*} from - 上个页面的功能
     * @param {*} data - 数据
     */
    async sendData(
        action,
        page,
        model,
        from,
        data
    ) {
        const val = this.getAllProperties();
        const combinedData = {
            ...val,
            action: action || this.action,
            page: page || this.page,
            model: model || this.model,
            from: from || this.from,
            data: JSON.stringify(data)
        };
        await this.fetchData('http://beh.caichong.net/s.html', 'GET', combinedData);
    }

    setLastFrom(newValue) {
        this.#lastFrom = newValue;
        return this;
    }

    // 拦截dom的click事件
    interceptClick(){
        document.addEventListener('click', (e) => {
            e.stopPropagation();
            const val = e.target.dataset.capture;
            if(val) {
                const {page, model, from, data} = JSON.parse(val);
                console.log(page, model, from, data)
                this.sendData('click', page, model, from, data);
            }
        });
    }

}

const dataCapture = new DataCapture();
export { DataCapture };
export default dataCapture;