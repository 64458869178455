import { useEffect, useState, useRef, useImperativeHandle, createPortal } from "react";
import Portal from "../portal";
import { getAuthMobileCodeAPI, getRiskVerifyAPI } from "@/apis/user"
import { toast } from "../modals";

import "./index.scss";

let captcha = null;

const Captcha = () => {
    const [show, setShow] = useState(false);
    const captchaRef = useRef(null);

    useImperativeHandle(captchaRef, () => ({
        show() {
            setShow(true);
            setTimeout(() => {
                document.getElementById('captcha-button').click(); // 触发验证码
            }, 1000);
        },
        hide() {
            setShow(false);
        }
    }));

    useEffect(() => {
        captcha = {
            show: () => {
                captchaRef.current.show();
            },
            hide: () => {
                captchaRef.current.hide();
            }
        };

        return () => {
            captcha = null;
        };
    }, []);

    const handleClose = () => {
        setShow(false);
    };


    let captchas;

    const getInstance = (instance) => {
        captchas = instance;
    };

    const captchaVerifyCallback = async (captchaVerifyParam) => {
        // 1.向后端发起业务请求，获取验证码验证结果和业务结果
        const res = await riskVerify('', captchaVerifyParam);
        if (res) {
            return {
                captchaResult: true, // 验证码验证是否通过，boolean类型，必选
                bizResult: true, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
            }
        }
        else {
            return {
                captchaResult: false, // 验证码验证是否通过，boolean类型，必选
                bizResult: false, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
                bizResultMsg: res.errmsg, // 业务验证不通过时的错误提示信息，可选
            }
        };
    }
    // 验证通过后调用
    const onBizResultCallback = () => {
        console.log('onBizResultCallback');
    }

    useEffect(() => {
        window.initAliyunCaptcha({
            SceneId: '1l357dko', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
            prefix: 't9am40', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
            mode: 'popup', //embed 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
            element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
            button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
            captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
            onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
            getInstance: getInstance, // 绑定验证码实例函数，无需修改
            slideStyle: {
                width: 360,
                height: 40,
            }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
            language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
        });
        return () => {
            // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
            document.getElementById('aliyunCaptcha-mask')?.remove();
            document.getElementById('aliyunCaptcha-window-popup')?.remove();
        }
    }, []);


    const getSmsCode = async (captchaVerifyParam) => {
        try {
            const rs = await getAuthMobileCodeAPI({
                captchaVerifyParam,
                sceneId: "1l357dko",
            });
            if (rs.retcode === 100) {
                toast.show(rs.message.msg, 3000);
            } else {
                toast.show(rs.errmsg);
            }
            return rs;
        } catch (err) {
            console.log(err);
        }
    };

    const riskVerify = async (e, captchaVerifyParam) => {
        try {
            const res = await getRiskVerifyAPI({
                sceneid: '1l357dko',
                code: captchaVerifyParam,
            });
            if (res.retcode === 100 || res.retcode === 200) {
                return true;
            } else if (res.retcode === 208) {
                document.getElementById('captcha-button').click(); //触发验证码
                return false;
            } else if (res.retcode === 402) {
                toast.show(res.errmsg);
                return true;
            }
            else {
                toast.show(res.errmsg);
                return false;
            }
        } catch (err) {
            console.log(err);
        }

    }

    return (
        show &&
        <Portal>
            <div className="captcha-b" ref={captchaRef}>
            </div>
        </Portal>
    );
};

export {
    Captcha,
    captcha
};
