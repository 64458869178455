import { useCallback, useEffect, useRef, useState } from "react";

const useAudio = (
//     {
//     // volume,
//     // autoPlay=true
// }
) => {

    // console.log('-----useAudio------')

    const [_pastTime, _setPasstTime] = useState(0);
    const [loading, setLoading] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [_duration, _setDuration] = useState(0);
    const [_volume, _setVolume] = useState(0.5);
    const timerRef = useRef(null);

    const audioRef = useRef(null);

    useEffect(() => {
        const _audioElement = document.createElement('audio');
        _audioElement.crossorigin = "anonymous";
        _audioElement.autoplay = false;
        _audioElement.addEventListener('play', ()=>{
            setPlaying(true);
        }, false);
        _audioElement.addEventListener('pause', ()=>{
            setPlaying(false);
        }, false);
        _audioElement.addEventListener('volumechange', ()=>{
            _setVolume(_audioElement.volume);
        }, false)
        _audioElement.addEventListener('canplay', ()=>{
            _setDuration(audioRef.current.duration);
            setLoading(false);
        }, false)
        audioRef.current = _audioElement 
    }, []);

    const updateTime = useCallback((state) => {
        // return;
        clearInterval(timerRef.current);
        if (state) {
            timerRef.current = setInterval(() => {
                _setPasstTime(audioRef.current.currentTime);
            }, 1000);
        }
    }, []);

    const destroy = useCallback(()=>{
        updateTime(false);
        _setPasstTime(0);
        audioRef.current.pause();
        audioRef.current.src = '';
        audioRef.current.load();
        // audioRef.current=null;
    }, [updateTime])

    const setSrc = useCallback((src) => {
        audioRef.current.src = '';
        audioRef.current.load();
        audioRef.current.src = src;
        setLoading(true);
        audioRef.current.play().then(rs => {
        }).catch(err => {
            console.log(err);
        });
        updateTime(true);
    }, [updateTime]);

    const play = useCallback(() => {
        audioRef.current.play();
    }, []);
    const pause = useCallback(() => {
        audioRef.current.pause();
    }, []);

    const setMute = useCallback((state) => {
        audioRef.current.volume = state?0:.5;
    }, []);

    const setVolume = useCallback((vol) => {
        if(audioRef.current)audioRef.current.volume = vol;
    }, []);

    const setPastTime = useCallback((seconds) => {
        if(audioRef.current){
            updateTime(false);
            audioRef.current.currentTime = seconds;
            updateTime(true);
        }
    }, [updateTime]);


    return {
        play,
        pause,
        setSrc,
        setVolume,
        setPastTime,
        setMute,
        pastTime: _pastTime,
        volume: _volume,
        duration: _duration,
        playing,
        loading,
        destroy
    }
}

export default useAudio;
