import {Modal as AntdModal, Space, Spin} from 'antd';
import './modal.scss';

const Modal = ({
    open,
    title = '',
    onCancel,
    content,
    onOk,
    footer = null,
    okTxt = '确定',
    cancelTxt = '取消',
    buttonLoading = false,
    loading = false,
    className,
    width = 520
}) => {
    return (
        <AntdModal title={title} open={open} onCancel={onCancel} footer={null} className={className} width={width}>
            <Spin spinning={loading}>
                <div>{content}</div>
                {footer && <div className="footer-box">{footer}</div>}
                {!footer && (
                    <div className="footer-box">
                        <Space>
                            <div
                                className="cancel-btn"
                                onClick={() => onCancel()}>
                                {cancelTxt}
                            </div>
                            <Spin spinning={buttonLoading}>
                                <div className="ok-btn" onClick={() => onOk()}>
                                    {okTxt}
                                </div>
                            </Spin>
                        </Space>
                    </div>
                )}
            </Spin>
        </AntdModal>
    );
};
export default Modal;
