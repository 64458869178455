import {useLocation, useNavigate} from 'react-router-dom';
import {Menu} from '../menu';
import User_panel from '../userPanel';
import './index.scss';
import React from 'react';

import backBtn from '@/assets/images/back-btn.png';

const Header = ({logo = true}) => {
    const location = useLocation();
    const navigate = useNavigate();

    // 检查当前路径是否匹配了音乐页面的路由
    const isMatchingPath =()=> {
        const path = location.pathname;
        const isMatch = ['/music', '/topic', '/music/', '/topic/'].includes(path);
        return !isMatch;
    }

    return (
        <header className="header">
            {isMatchingPath() ? (
                <div className="back_btn" onClick={()=>{navigate(-1)}}>
                    <img src={backBtn} alt="" />
                </div>
            ) : (
                <div></div>
            )}
            <User_panel />
            <Menu />
        </header>
    );
};

export default Header;
