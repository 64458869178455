import {useEffect, useState} from 'react';
import useModalFade from '@/hooks/useModalFade';
import Portal from '../portal';
import classNames from 'classnames';

import './index.scss';

let alert = {};
const Alert = () => {
    const {show, visible, modalRef} = useModalFade();
    const [info, setInfo] = useState({title: '', content: ''});

    useEffect(() => {
        alert = {
            show: (msg) => {
                let _info = {};
                if (typeof msg == 'string') {
                    _info = {
                        title: 'chaoxin提示您',
                        content: msg
                    };
                } else {
                    _info = msg;
                }
                setInfo(_info);
                modalRef.current.show();
            },
            hide: modalRef.current.hide
        };
        return () => {
            modalRef.current.hide();
        };
    }, [modalRef]);
    return (
        show && (
            <Portal>
                <div
                    className={classNames(
                        'alert-panel-bg',
                        visible && 'alert-panel-bg-v'
                    )}>
                    <div className="alert-panel">
                        <div className="ap-header">
                            <span className="aph-bg"></span>
                            <span
                                className="ap-close"
                                onClick={() => alert.hide()}></span>
                        </div>
                        <div className="ap-content">
                            <h6>{info.title}</h6>
                            <div>
                                <span>{info.content}</span>
                            </div>
                        </div>
                        <div className="ap-button" onClick={() => alert.hide()}>
                            OK
                        </div>
                    </div>
                </div>
            </Portal>
        )
    );
};

export {Alert, alert};
