import { useState, useEffect } from "react";
import useModalFade from '../../hooks/useModalFade'
import classNames from "classnames";
import Portal from "../portal";
import user from "@/utils/user";

import qrcode from "../../assets/images/temp/qrcode.png";
import pay_label from "../../assets/images/benefit_label.png";


import "./index.scss";

const randomNum = function (num) {
    return Math.floor(Math.random() * num);
}
const item = {
    tm: '2024.01.23 23:34',
    exp: '2024.01.23 23:34',
    order: 'CC202401232334776251'
}
const types = ['vip', 'svip'];
const ways = ['支付宝', '微信'];
const historyList = []
for (var i = 0; i < 20; i++) {
    historyList.push({ id: i, type: types[randomNum(2)], way: ways[randomNum(2)], ...item })
}
const PayHistory = ({ closeHistory, show, visible }) => {
    const [list, setList] = useState(historyList);
    return (
        show&&
        <div className={classNames("pay-history", visible&&"pay-history-v")}>
            <div className="ph-header">
                <h6>
                    <i title="返回" onClick={closeHistory}></i>
                    <span>充值记录</span>
                </h6>
                <div onClick={payPanel.hide} title="关闭"></div>
            </div>
            <div className="ph-thead">
                <span>服务</span>
                <span>开通时间</span>
                <span>有效期至</span>
                <span>充值方式</span>
                <span>订单号</span>
            </div>
            <div className="ph-tbody">
                <ul>
                    {list.map(item => (
                        <li key={item.id}>
                            <span>{item.type}</span>
                            <span>{item.tm}</span>
                            <span>{item.exp}</span>
                            <span>{item.way}</span>
                            <span>{item.order}</span>
                        </li>
                    ))}

                </ul>
            </div>
        </div>
    )
}

let payPanel = {};
const PayPanel = () => {

    const [showVip, setShowVip] = useState(true);
    const { show, visible, modalRef } = useModalFade();

    const {show:hisShow , visible: hisVisible, modalRef:historyRef} = useModalFade({scrollHide:false});

    useEffect(() => {
        payPanel = modalRef.current;
        
        return ()=>{
            payPanel.hide();
        }
    }, []);

    const historyShow = ()=>{
        historyRef.current.show();
    }
    const historyHide = ()=>{
        historyRef.current.hide();
    }
    const panelHide = () => {
        payPanel.hide();
    }

    useEffect(()=>{
        if(user.member>1){
            setShowVip(false);
        }
    }, [user.member])

    return (
        show &&
        <Portal>
            <div className={classNames("pay-panel-bg", visible && "pay-panel-bg-v")}>
               <PayHistory closeHistory={historyHide} show={hisShow} visible={hisVisible} />
                <div className={classNames("pay-panel", !showVip && "pay-panel-svip")}>
                    <div className="pay-panel-close" onClick={panelHide}></div>
                    <div className="pay-panel-header">
                        <div className="pay-panel-user">
                            <div className="user-avatar">
                                <img src={user.avatar} />
                                {user.memter > 0 && (<span className={`vip-${user.memter}`}></span>)}
                            </div>
                            <div className="user-info">
                                <span>1392804756</span>
                                <span>VIP将于2024.06.12过期<i onClick={historyShow}>充值记录</i></span>
                            </div>
                        </div>
                        <div className="points-info">
                            <div className="points-total"><span>6128</span><i>/7200</i></div>
                            <div className="points-bar"><i style={{ width: '65%' }}></i></div>
                        </div>
                    </div>
                    <div className="pay-panel-tab">
                        <span title="vip" className="ppt-vip" onClick={() => setShowVip(true)}></span>
                        <span title="svip" className="ppt-svip" onClick={() => setShowVip(false)}></span>
                    </div>
                    <div className="pay-container">
                        <div className="pay-container-section pay-container-vip">
                            <div className="benefits">
                                <div>VIP权益</div>
                                <ul>
                                    <li>7200点数</li>
                                    <li>描述语、歌词、语音输入</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>下载mp4、mp3 192kbps</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>其他功能描述</li>
                                </ul>
                            </div>
                            <div className="pay-options">
                                <ul>
                                    <li className="pay-continue">
                                        <span className="pay-option-label">
                                            <img src={pay_label} />
                                        </span>
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">33元/首</span>
                                        </div>
                                    </li>
                                    <li className="pay-twelve">
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">33元/首</span>
                                        </div>
                                    </li>
                                    <li className="pay-six">
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">33元/首</span>
                                        </div>
                                    </li>
                                    <li className="pay-one">
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">1.1元/首</span>
                                        </div>
                                    </li>
                                </ul>
                                <div className="pay-scan-qrcode">
                                    <div className="qrcode"><img src={qrcode} /></div>
                                    <div className="qrcode-info">
                                        <p className="qrcode-text"><span>实付：¥</span><i>119.00</i></p>
                                        <p className="qrcode-text"><span>请使用</span><span className="alipay">支付宝或</span><span className="wxpay">微信扫码支付</span></p>
                                        <p className="agreement-info"><span>开通即视为同意</span><a href="#">《Chaoxin会员服务协议》</a><a href="#">《自动续费协议》</a><a href="">《隐私政策》</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pay-container-section pay-container-svip">
                            <div className="benefits">
                                <div>SVIP权益</div>
                                <ul>
                                    <li>7200点数</li>
                                    <li>描述语、歌词、语音输入</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>下载mp4、mp3 192kbps</li>
                                    <li>7200点数</li>
                                    <li>7200点数</li>
                                    <li>其他功能描述</li>
                                </ul>
                            </div>
                            <div className="pay-options">
                                <ul>
                                    <li className="pay-continue">
                                        <span className="pay-option-label">
                                            <img src={pay_label} />
                                        </span>
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">33元/首</span>
                                        </div>
                                    </li>
                                    <li className="pay-twelve">
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">33元/首</span>
                                        </div>
                                    </li>
                                    <li className="pay-six">
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">33元/首</span>
                                        </div>
                                    </li>
                                    <li className="pay-one">
                                        <div>
                                            <span className="pay-option-title">连接包月</span>
                                            <span className="original-price">¥59</span>
                                            <span className="current-price">¥<i>33.2</i></span>
                                            <span className="unit-price">1.1元/首</span>
                                        </div>
                                    </li>
                                </ul>
                                <div className="pay-scan-qrcode">
                                    <div className="qrcode"><img src={qrcode} /></div>
                                    <div className="qrcode-info">
                                        <p className="qrcode-text"><span>实付：¥</span><i>119.00</i></p>
                                        <p className="qrcode-text"><span>请使用</span><span className="alipay">支付宝或</span><span className="wxpay">微信扫码支付</span></p>
                                        <p className="agreement-info"><span>开通即视为同意</span><a href="#">《Chaoxin会员服务协议》</a><a href="#">《自动续费协议》</a><a href="">《隐私政策》</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Portal >
    )
}

export {
    PayPanel,
    payPanel
}