import copy from 'copy-to-clipboard';
import {toast} from '@/components/modals';
// import { getShareInfoAPI } from "@/apis/product";

// function shareMusic(mid) {
//     return new Promise(async (resolve, reject) => {
//         const copyMsg = (shareInfo) => {
//             copy(`${shareInfo.sharemsg}：${shareInfo.shareurl}`);
//             toast.show("已复制到剪贴板", 3000);
//         }

//         const setMeta = (shareInfo, state) => {
//             if(state!==1){
//                 shareInfo = {
//                     sharemsg: 'chaoxin',
//                     img:window.location.hostname+'/logo192.png',
//                     shareurl:window.location.hostname
//                 }
//             }
//             document.querySelector('meta[property="og:title"]').setAttribute('content', shareInfo.sharemsg);
//             document.querySelector('meta[property="og:img"]').setAttribute('content', shareInfo.img);
//             document.querySelector('meta[property="og:url"]').setAttribute('content', shareInfo.shareurl);
//         }
//         let shareInfo = null;
//         try {
//             const _res = await getShareInfoAPI({ mid: mid });
//             if (_res.retcode === 100) {

//                 shareInfo = _res.message;
//                 setMeta(shareInfo, 1);
//                 if (navigator.canShare) {
//                     try {
//                         navigator.share({
//                             title: shareInfo.title,
//                             url: shareInfo.shareurl,
//                             text: shareInfo.sharemsg

//                         }).catch((err) => {
//                             // copyMsg(shareInfo);
//                         });
//                     } catch (err) {
//                         copyMsg(shareInfo);
//                     }

//                 } else {
//                     copyMsg(shareInfo);
//                 }

//             } else {
//                 toast.show(_res.errmsg);
//             }
//             resolve(shareInfo);
//         } catch (err) {
//             reject(err.message);
//         }
//     })
// }

function shareMusic(music) {
    const copyMsg = (shareInfo) => {
        copy(`${shareInfo.sharemsg}：${shareInfo.shareurl}`);
        toast.show('分享链接复制成功', 3000);
    };

    const shareInfo = {
        title: music.title,
        // shareurl: "https://caichong.net/share?mid=" + music.mid,
        shareurl: window.location.origin + '/share?mid=' + music.mid,
        sharemsg: `${music.title} -- ${
            music.nickname ? music.nickname : music.author
        }`,
        img: music.img
    };
    copyMsg(shareInfo);
    /*
     const setMeta = (shareInfo, state) => {
        if (state !== 1) {
            shareInfo = {
                sharemsg: 'chaoxin',
                img: window.location.hostname + '/logo192.png',
                shareurl: window.location.hostname
            }
        }
        document.querySelector('meta[property="og:title"]').setAttribute('content', shareInfo.sharemsg);
        document.querySelector('meta[property="og:image"]').setAttribute('content', shareInfo.img);
        document.querySelector('meta[property="og:url"]').setAttribute('content', shareInfo.shareurl);
    }
    setMeta(shareInfo, 1);
    if (navigator.canShare) {
        try {
            navigator.share({
                title: shareInfo.title,
                url: shareInfo.shareurl,
                text: shareInfo.sharemsg

            }).then(() => {
                // setMeta(shareInfo, 0);
            }).catch((err) => {
                // copyMsg(shareInfo);
                // setMeta(shareInfo, 0);
            });
        } catch (err) {
            copyMsg(shareInfo);
            // setMeta(shareInfo, 0);
        }

    } else {
        copyMsg(shareInfo);
        // setMeta(shareInfo, 0);
    }
    */
}

export default shareMusic;
