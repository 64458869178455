import {Link} from 'react-router-dom';
import './index.scss';
import React, {useState} from 'react';
import {Image} from 'antd';

// import footer_img from "../../assets/images/footer_logo.png";
// const Footer = ()=>{
//     return (
//         <div className="footer">
//             <div className="fp-left">
//                 <img src={footer_img} />
//                 <span>© 北京chaoxin音乐科技有限公司</span>
//             </div>
//             <div className="fp-right">
//             京公网安备33029388271823号<br/>
//             京ICP备24099811号-3
//             </div>
//         </div>
//    )
// }

const Footer = () => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="footer">
            <div className="fp-left">
                <a href="/"></a>
                <Image
                    width={0}
                    height={0}
                    style={{display: 'none'}}
                    className="footer-img-preview"
                    src={require('../../assets/images/telecom.jpg')}
                    preview={{
                        visible,
                        onVisibleChange: (value) => {
                            setVisible(value);
                        },
                        toolbarRender: () => null
                    }}
                />
            </div>

            <div className="fp-right">
                <Link
                    to="https://beian.miit.gov.cn/"
                    target="_blank"
                    style={{marginBottom: '-5px'}}>
                    沪ICP备15056561号-2
                </Link>
            </div>
        </div>
    );
};

export default Footer;
