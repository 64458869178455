import { useState, useRef, useImperativeHandle } from 'react';
import { pageScrollHide } from '@/utils/base';

const useModalFade = (config = {}) => {
    const { show: isShow = false, scrollHide = true } = config;
    const [visible, setVisible] = useState(isShow);
    const [show, setShow] = useState(isShow);
    const selfRef = useRef(null);

    useImperativeHandle(selfRef, () => {
        return {
            show() {
                setTimeout(() => {
                    setVisible(true)
                }, 16)
                if (scrollHide) pageScrollHide(1);
                return setShow(true);
            },
            hide() {
                setTimeout(() => {
                    setShow(false);
                }, 300)
                if (scrollHide) pageScrollHide(0)
                return setVisible(false);
            }
        }
    });

    return { show, visible, modalRef: selfRef };
}

export default useModalFade;