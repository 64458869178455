 function getLimitSize(width, height, maxWidth, maxHeight) {
    var ratio = width / height;
    var canvasRatio = maxWidth / maxHeight;
    var newWidth;
    var newHeight;
    if (width <= maxWidth && height <= maxHeight) {
        return {
            width: width,
            height: height,
            scale: 1
        };
    }
    if (ratio >= canvasRatio) {
        newWidth = maxWidth;
        newHeight = newWidth / ratio;
    } else {
        newHeight = maxHeight;
        newWidth = newHeight * ratio;
    }

    return {
        width: newWidth,
        height: newHeight,
        scale: newWidth / width
    };
};

function getCharLength(str) {
    let realLength = 0;
    for (let i = 0; i < str.length; i++) {
       let charCode = str.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128)
            realLength += 1;
        else
            realLength += 2;
    }
    return realLength;
}

function pageScrollHide(state){
    if(state===1){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = 'unset';
    }
}

export {
    getLimitSize,
    getCharLength,
    pageScrollHide
}