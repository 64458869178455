import {getMusicSourceAPI, likeupAPI} from '@/apis/product';
import {useAudioPlayerStore, useMusicTitleUpdateStore} from '@/store';
import shareMusic from '@/utils/shareMusic';
import user from '@/utils/user';
import classNames from 'classnames';
import {
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {createPortal} from 'react-dom';
import useAudio from '../../hooks/useAudio';
import useDrag from '../../hooks/useDrag';
import {login, toast} from '../modals';
import './index.scss';
import {useNavigate} from 'react-router-dom';
import dataCapture from '@/utils/dataCapture';

const volumeFormat = (num) => {
    return (num / 1 + 0.05 - (num % 0.05)).toFixed(2);
};

const getVolumePosition = (volume) => {
    return Math.ceil(volume * 60);
};
const timeFormat = (seconds) => {
    return [seconds / 60, seconds % 60]
        .map((v) => `0${Math.floor(v)}`.slice(-2))
        .join(':');
};

const JSON = window.JSON;
const Audio = ({visible, isLikeup, list, ...props}) => {
    const navigate = useNavigate();

    // src, volume

    // 用于，我的创作，系统推荐两个列表播放状态样式
    const setCurMusicMid = useAudioPlayerStore((state) => state.setMid);
    const setPlaying = useAudioPlayerStore((state) => state.setPlaying);
    const historyRef = useRef([]);

    const [musicIndex, setmusicIndex] = useState(0);

    const togglePlay = (e) => {
        if (e.target.className.indexOf('pause') < 0) {
            play();
        } else {
            pause();
        }
    };

    const toggleMute = (e) => {
        if (e.target.className.indexOf('-on') > -1) {
            setVolumePosition(33);
        } else {
            setVolumePosition(0);
        }
    };

    const next = useCallback(() => {
        const _index = (musicIndex + 1) % historyRef.current.length;
        setmusicIndex(_index);
        nextMusic(historyRef.current[_index].mid);
    }, [historyRef, musicIndex]);
    const prev = useCallback(() => {
        let _index = musicIndex - 1;
        if (_index < 0) _index = historyRef.current.length - 1;
        setmusicIndex(_index);
        nextMusic(historyRef.current[_index].mid);
    }, [historyRef, musicIndex]);
    const {
        setSrc,
        duration,
        pastTime,
        setPastTime,
        setVolume,
        // setMute,
        play,
        pause,
        playing,
        loading,
        // volume,
        destroy
    } = useAudio({next});

    const nextMusic = useCallback(
        async (_mid) => {
            destroy();
            setCurMusicMid(_mid);
            let _music = historyRef.current.find(
                (item) => item.mid === _mid && item.init
            );
            if (_music) {
                // currentMusic = _music;
                setCurrentMusic(_music);
                setSrc(_music.src);
            } else {
                try {
                    const rs = await getMusicSourceAPI({mid: _mid});
                    dataCapture.sendData('click', undefined, 'play', '',{mid: _mid});
                    if (rs.retcode === 100) {
                        _music = rs.message;
                        _music.mid = _mid;
                        if (typeof _music.lyrics === 'string') {
                            try {
                                _music.lyrics = JSON.parse(_music.lyrics);
                            } catch (e) {
                                let lrcArr = _music.lyrics.split(/[\r\n]+/);
                                _music.lyrics = lrcArr.map((str) => {
                                    let _arr = str.match(
                                        /^\[(\d+)\D(\d+)\D(\d+)\](.*)$/
                                    );
                                    _arr = Array.prototype.concat.apply(
                                        [],
                                        _arr
                                    );
                                    return {
                                        start:
                                            ((_arr[1] / 1) * 60 + _arr[2] / 1) *
                                            1000,
                                        text: _arr[4]
                                    };
                                });
                            }
                        }

                        if (
                            !(_music.lyrics instanceof Array) ||
                            !_music.lyrics.map
                        ) {
                            _music.lyrics = [];
                        }

                        _music.lyrics.map(
                            (lyric) =>
                                (lyric.start = Math.floor(lyric.start / 1000))
                        );
                        _music.src = _music.url;
                        _music.lyrics.sort((a, b) => a.start - b.start);
                        _music.mid = _mid;
                        _music.init = true;
                        let _music_ = historyRef.current.find(
                            (item) => item.mid === _mid
                        );
                        if (_music_) {
                            Object.assign(_music_, _music);
                        } else {
                            historyRef.current.push(_music);
                        }
                        // currentMusic = _music;
                        setCurrentMusic(_music);
                        setSrc(_music.src);
                    } else {
                        toast.show(rs.errmsg);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [destroy, setCurMusicMid, setSrc]
    );

    // const selfRef = useRef(null);
    // useEffect(()=>{
    //     selfRef.current = {
    //         play,
    //         pause,
    //         setMute,
    //         volume
    //     }
    // }, [])

    useEffect(() => {
        audioPanel.pause = pause;
        audioPanel.play = play;
    }, [pause, play]);

    // var [lyric, setLyric] = useState('');

    // const [music, setMusic] = useState({});

    const [volumePosition, setVolumePosition] = useState(
        getVolumePosition(props.volume || 0.5)
    );
    const [timePostion, setTimePosition] = useState(0);
    const [currentMusic, setCurrentMusic] = useState({});
    const timeBarRef = useRef(null);
    const timeSliderRef = useRef(null);
    const volumeBarRef = useRef(null);
    const volumeSliderRef = useRef(null);
    const timeDragingRef = useRef(false);

    const audioStatusRef = useRef(null);

    useEffect(() => {
        audioStatusRef.duration = duration;
    }, [duration]);

    useEffect(() => {
        setPlaying(playing);
    }, [playing, setPlaying]);

    useEffect(() => {
        // 如果是列表，更新本地列表，
        if (list.length > 0) {
            historyRef.current = list.map((item) => ({
                mid: item.mid,
                init: false
            }));
        }
        nextMusic(list[0].mid);
    }, [list[0].mid]);

    useEffect(() => {
        return destroy;
    }, [destroy]);

    const getLyric = useCallback(
        (seconds) => {
            if (currentMusic?.lyrics?.length < 1) return {text: ''};
            return (
                currentMusic?.lyrics?.findLast(
                    (lyric) => seconds >= lyric.start
                ) || currentMusic.lyrics[0]
            );
        },
        [currentMusic]
    );

    const [timeDragPosition] = useDrag({
        axis: 'x',
        bounds: {left: 0, parent: {bound: 'width'}},
        defaultPosition: {x: 0},
        onDragStart: () => {
            timeDragingRef.current = true;
        },
        onDragStop: (x, y) => {
            const _curTime =
                (x / timeBarRef.current.offsetWidth) * audioStatusRef.duration;
            setPastTime(_curTime);
            timeDragingRef.current = false;
        },
        ref: timeSliderRef
    });

    //拖拽过程同步时间UI进度条
    useEffect(() => {
        setTimePosition(timeDragPosition.x);
    }, [timeDragPosition]);

    // 播放过程中同步时间UI进度条, 歌曲
    useEffect(() => {
        if (!playing) return;
        if (timeDragingRef.current) return;
        const _position =
            (pastTime / duration) * timeBarRef.current.offsetWidth;
        setTimePosition(_position || 0);
        // const _lyric = getLyric(Math.floor(pastTime));
        // setLyric(_lyric.text);
    }, [pastTime, duration, getLyric, playing]);

    const onClickTimeBar = (event) => {
        if (loading || timeDragingRef.current) return;
        const x = event.pageX - timeBarRef.current.getBoundingClientRect().left;
        setTimePosition(x);
        const _curTime = (x / timeBarRef.current.offsetWidth) * duration;
        setPastTime(_curTime);
    };

    const [volumeDragPosition /*, volumeDraging*/] = useDrag({
        axis: 'x',
        bounds: {left: 0, right: 60},
        defaultPosition: {x: volumePosition},
        ref: volumeSliderRef
    });
    //拖拽过程同步音量条
    useEffect(() => {
        setVolumePosition(volumeDragPosition.x);
    }, [volumeDragPosition]);

    // 音量滑块更新后同步audio音量
    useEffect(() => {
        let _vol = volumeFormat((volumePosition / 60).toFixed(2));
        if (_vol < 0.1) {
            _vol = 0;
        }
        setVolume(_vol);
    }, [volumePosition, setVolume]);

    const onClickVolumeBar = (event) => {
        if (!loading) {
            const x =
                event.pageX - volumeBarRef.current.getBoundingClientRect().left;
            setVolumePosition(x);
        }
    };

    const rqingRef = useRef(false);
    const likeup = async (mid) => {
        dataCapture.sendData('click', undefined, 'do_interest', '', {mid: mid, interest: currentMusic.favflag === 1 ? 0 : 1});

        if (!user.isLogin()) {
            login.show();
            toast.show('请先登录');
        } else {
            if (rqingRef.current) return;
            rqingRef.current = true;
            const like = currentMusic.favflag === 1 ? 0 : 1;
            try {
                const res = await likeupAPI({mid, style: like});
                if (res.retcode === 100) {
                    currentMusic.favflag = like;
                    setCurrentMusic(currentMusic);
                } else if (res.recode === 203) {
                    login.show();
                    toast.show('请先登录');
                } else {
                    toast.show(res.errmsg);
                }
            } catch (err) {
                console.log(err.message);
                toast.show('网络错误请重试');
            }
            rqingRef.current = false;
        }
    };

    const modMmusicTitleData = useMusicTitleUpdateStore((state) => state.data);
    if (modMmusicTitleData?.mid === currentMusic?.mid) {
        currentMusic.title = modMmusicTitleData.title;
    }

    // 跳转到音乐详情
    const gotoMusic = (id) => {
        navigate(`/music/${id}`);
    };

    return (
        <div
            className={classNames(
                'audio-panel',
                visible && 'audio-panel-v',
                loading && 'audio-loading'
            )}>
            <div
                className="time-slider"
                ref={timeBarRef}
                onClickCapture={onClickTimeBar}>
                <div
                    className="time-slider-fill"
                    style={{width: timePostion}}></div>
                <div
                    className="time-handle"
                    ref={timeSliderRef}
                    style={{transform: `translate(${timePostion}px, 0)`}}></div>
            </div>
            <div className="audio-body">
                <div className="music-info">
                    <div
                        className="music-poster"
                        onClick={()=>{gotoMusic(currentMusic?.mid)}}
                        style={{
                            backgroundImage: `url(${currentMusic?.img})`,
                            backgroundSize: 'cover'
                        }}></div>
                    <div className="music-ext">
                        <div className="music-title" 
                            onClick={()=>{gotoMusic(currentMusic?.mid)}}>
                            {currentMusic?.title}
                        </div>
                        <div className="music-time">
                            {timeFormat(pastTime)}/{timeFormat(duration)}
                        </div>
                    </div>
                </div>

                <div className="ap-buttons">
                    <div className="ap-prev" onClick={prev}></div>
                    <div
                        className={classNames(
                            'ap-start',
                            playing && 'ap-pause'
                        )}
                        onClick={togglePlay}></div>
                    <div className="ap-next" onClick={next}></div>
                </div>
                <div className="ap-layout">
                    <div className="ap-additional">
                        <div
                            className={classNames(
                                'likeup-btn',
                                currentMusic?.favflag === 1 && 'likeup-btn-ed',
                                isLikeup === false && 'likeup-btn-hide'
                            )}
                            onClick={() => likeup(currentMusic?.mid)}></div>
                        <div
                            className="share-btn"
                            onClick={() => shareMusic(currentMusic)}></div>
                        <div
                            className={classNames(
                                'ap-volume',
                                volumePosition === 0
                                    ? 'ap-volume-mute'
                                    : volumePosition < 30
                                    ? 'ap-volume-1'
                                    : volumePosition < 60 && 'ap-volume-2'
                            )}>
                            <div
                                className={classNames(
                                    'ap-volume-icon',
                                    volumePosition < 1 && 'ap-volume-icon-on'
                                )}
                                onClick={toggleMute}></div>
                            <div
                                className="volume-slider"
                                ref={volumeBarRef}
                                onClickCapture={onClickVolumeBar}>
                                <div
                                    className="volume-slider-fill"
                                    style={{width: volumePosition}}></div>
                                <span
                                    className="volume-handle"
                                    ref={volumeSliderRef}
                                    style={{
                                        transform: `translate(${volumePosition}px, 0)`
                                    }}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

let audioPanel = null;

const AudioPanel = memo(() => {
    const [show, setShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const [params, setParams] = useState({});
    const {setIsPanelShow} = useAudioPlayerStore();

    const audioPanelRef = useRef(null);

    useImperativeHandle(audioPanelRef, () => {
        return {
            timer: 0,
            show() {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    setVisible(true);
                }, 16);
                return setShow(true);
            },
            hide() {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    setShow(false);
                }, 300);
                return setVisible(false);
            }
        };
    });

    useEffect(() => {
        audioPanel = {
            show: (params) => {
                setIsPanelShow(true);
                setParams(params);
                audioPanelRef.current.show();
            },
            hide: () => {
                audioPanelRef.current.hide();
            }
        };
    }, []);

    const {list = [], ..._props} = params;
    if (_props?.mid && _props?.mid !== '') {
        list.push({mid: params.mid});
    }

    return createPortal(
        show && <Audio visible={visible} list={list} {..._props} />,
        document.getElementById('root')
    );
});

export {AudioPanel, audioPanel};
