
import {Login, login} from './login';
import { PayPanel, payPanel } from './payPanel';
import {Alert, alert} from './alert';
import { Confirm, confirm } from './confirm';
import { Toast, toast } from './toast';

import { AlertActive, alertActive } from './alertActiveCode';
// import { SharePopupNew,sharePopupNew } from './sharePopupNew';

export default function Modals (){

    return (
        <>
        <Login />
        <PayPanel />
        <Alert />
        <Toast />
        <Confirm />
        <AlertActive />
        {/* <SharePopupNew /> */}
        </>
    )
}

export {
    login,
    payPanel,
    alert,
    toast,
    confirm,
    // sharePopupNew,
    alertActive
}