import {feedbackApi} from '@/apis/createMusic';
import Modal from '@/components/modal/index';
import {Input} from 'antd';
import {useState} from 'react';
import user from '@/utils/user';
import {toast} from '@/components/modals';

const {TextArea} = Input;
const FeedBack = () => {
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState('');
    const [showError, setShowError] = useState('');
    const submit = async () => {
        if (!val) {
            setShowError('请输入');
            return;
        }
        try {
            const {retcode, errmsg, message} = await feedbackApi({
                content: val
            });
            if (retcode === 100) {
                setOpen(false);
            } else {
                setShowError(errmsg);
            }
        } catch (error) {
            setShowError(error);
            console.error(error);
        }
    };
    return (
        <div>
            <img
                style={{
                    position: 'fixed',
                    cursor: 'pointer',
                    right: '-20px',
                    bottom: '70px',
                    transform: 'scale(0.5)',
                    zIndex: '15'
                }}
                src={require('@/assets/images/newPage/feedback.png')}
                alt=""
                onClick={() => {
                    user.forceLogin().then((ok) => {
                        if (ok) {
                            setOpen(true);
                        } else {
                            toast.show('请先登录');
                        }
                    });
                }}
            />
            <Modal
                content={
                    <div>
                        <TextArea
                            style={{
                                marginTop: '20px',
                                background: '#F9F9F9',
                                border: '1px solid rgba(17, 17, 17, 0.05)'
                            }}
                            placeholder={'请输入'}
                            className="input-TextArea"
                            autoSize={{minRows: 3}}
                            maxLength={512}
                            value={val}
                            onChange={(e) => {
                                setVal(e.target.value);
                            }}
                        />
                        <div style={{color: 'red', marginTop: '5px'}}>
                            {showError}
                        </div>
                    </div>
                }
                footer={
                    <div
                        onClick={submit}
                        style={{
                            width: '200px',
                            height: '36px',
                            borderRadius: '10px',
                            marginTop: '30px',
                            textAlign: 'center',
                            lineHeight: '36px',
                            fontSize: '18px',
                            fontWeight: 400,
                            cursor: 'pointer',
                            background:
                                '#363636',
                            color: '#fff'
                        }}>
                        提交
                    </div>
                }
                open={open}
                title="意见反馈"
                onOk={() => {
                    submit();
                }}
                onCancel={() => {
                    setVal(undefined);
                    setOpen(false);
                }}
            />
        </div>
    );
};
export default FeedBack;
