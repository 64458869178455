import { useEffect, useState } from "react";
import Portal from "./portal";
import useModalFade from "@/hooks/useModalFade";

let toast = {};
const Toast = () => {

    const { show, visible, modalRef } = useModalFade();
    const [ msg, setMsg] = useState("")

    useEffect(() => {
        let timer;
        toast = {
            show: (msg, delay = 3000) => {
                setMsg(msg);
                modalRef.current.show();
                clearTimeout(timer);
                timer = setTimeout(modalRef.current.hide, delay);
            },
            hide: modalRef.current.hide
        }
        return ()=>{
            clearTimeout(timer);
        }
    }, []);
    return (
        show &&
        <Portal>
            <div 
                style={{
                    minWidth:120,
                    padding:"6px 12px",
                    position:"fixed",
                    zIndex:999,
                    left:"50%",
                    top:"50%",
                    textAlign:"center",
                    color:"#fff",
                    borderRadius:6,
                    background:"rgba(0,0,0,.82)",
                    opacity:visible?1:0,
                    transform:visible?"translate(-50%, -50%)":"translate(-50%, -40%)",
                    transition:"all .3s"
                }} 
            >
              <span>{msg}</span>
            </div>
        </Portal>
    )
}

export {
    Toast,
    toast
}