import {request} from '@/utils';

// 获取随机创意
export async function getRandomPrompt() {
    return request({
        url: '/v3/music/random_prompt',
        method: 'GET'
    });
}
// 获取随机风格
export async function getRandomStyle(body) {
    return request({
        url: '/v3/style/random',
        method: 'POST',
        data: body
    });
}
// 创建歌词任务
export async function createLyricTaskApi(body) {
    return request({
        url: '/v3/music/lyric/create',
        method: 'POST',
        data: body
    });
}
//查询歌词任务
export async function queryTaskApi(body) {
    return request({
        url: '/v3/music/lyric/get',
        method: 'POST',
        data: body
    });
}
//搜索话题
export async function searchTopicApi(body) {
    return request({
        url: '/v3/music/subject/get',
        method: 'POST',
        data: body
    });
}

//创建音乐生成任务
export async function createMusicTaskApi(body) {
    return request({
        url: '/v3/music/create/music',
        method: 'POST',
        data: body
    });
}

//创建图片内容生成任务
export async function createPhotoTaskApi(body) {
    return request({
        url: '/v3/pic/discern/create',
        method: 'POST',
        data: body
    });
}

//创建图片内容生成任务
export async function queryPhotoTaskApi(body) {
    return request({
        url: '/v3/pic/discern/get',
        method: 'POST',
        data: body
    });
}

//反馈
export async function feedbackApi(body) {
    return request({
        url: '/feedback',
        method: 'POST',
        data: body
    });
}






